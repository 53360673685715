@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --topbar-height: 100px;
  --shadow-button: 0 0 8px 0 rgba(17, 22, 26, 0.16),
    0 4px 8px 0 rgba(17, 22, 26, 0.08), 0 8px 16px 0 rgba(17, 22, 26, 0.08);
  --shadow-card: 0 0 4px 0 rgba(17, 22, 26, 0.16),
    0 2px 4px 0 rgba(17, 22, 26, 0.08), 0 4px 8px 0 rgba(17, 22, 26, 0.08);
}

@layer base {
  html,
  body,
  #root {
    @apply h-full;
  }
}
